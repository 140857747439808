
.landing-page {
  background: linear-gradient(to bottom, #000000, #333333);
  height: 100%;
}

/* Scrolling Text Container */
.scrolling-text-container {
  width: 100%;
  height: 70px;
  overflow: hidden;
  background-color: #15583a  ;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Scrolling Text with Animation */
.scrolling-text {
  display: inline-block;
  white-space: nowrap;
  animation: scroll-text 12s linear infinite, glow-text 2s ease-in-out infinite alternate;
  font-size: 42px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  background: linear-gradient(90deg, #FFAA00, #FF4081, #00E676, #00D4FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.4);
  text-transform: capitalize;
  letter-spacing: 2px;
}



/* Keyframes for Scrolling Animation */
@keyframes scroll-text {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.Trip-main {
  padding: 15px;
}

.CompanyMainImg-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.CompanyMainImg-container img {
  width: 100%;
  height: 100vh;
  border-radius: 10px;
}

.CompanyMainImg-overlay {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

/* .CompanyMainImg-text-1 {
  font-family: Cursive;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: gold;
  font-size: 35px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 1;
} */

/* .CompanyMainImg-text-2 {
  font-family: Cursive;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: gold;
  font-size: 20px;
  font-weight: 500;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 1;
} */



/* About Us Section */
.aboutus-landing {
  background-color: transparent;
  padding: 50px 150px;
  border-radius: 8px;
  margin: 50px 0 50px 0;
  color: whitesmoke;
  border: 1px solid whitesmoke;
}

.aboutus-landing h3 {
  text-align: justify;
  margin-bottom: 10px;
}

.aboutus-landing {
  text-align: justify;
}




/* Bottom Landing */
.bottomlanding {
  background-color: transparent;
}

.bottomlanding-2 {
  padding-top: 30px;
  display: flex;
  justify-content: center;
}

.bottomlanding-2 img {
  cursor: pointer;
  margin-right: 10px;
  width: 30px;
}

.bottomlanding-1 {
  display: grid;
  grid-template-columns: 2fr 1.5fr 0.7fr;
  gap: 20px;
  text-align: left;
}

.bottomlanding-1 .first,
.bottomlanding-1 .Second,
.bottomlanding-1 .Third {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: whitesmoke;
  border: 1px solid white;
}

.bottomlanding-1 .first .companydetails {
  display: flex;
  align-items: center;
}

.bottomlanding-1 .first img,
.bottomlanding-1 .Second img,
.bottomlanding-1 .Third img {
  height: 50px;
  border-radius: 50px;
  margin-bottom: 8px;
}




/* Styles for devices with a maximum width of 768px (e.g., tablets) */
@media (max-width: 768px) {
  .scrolling-text {
    font-size: 32px;
  }
  .CompanyMainImg-text-1 {
    font-size: 28px;
  }
  .CompanyMainImg-text-2 {
    font-size: 16px;
  }
  .aboutus-landing {
    padding: 30px 20px;
  }
  .bottomlanding-1 {
    grid-template-columns: 1fr;
  }
  .bottomlanding-2 img {
    width: 25px;
  }
}

/* Styles for devices with a maximum width of 480px (e.g., smartphones) */
@media (max-width: 480px) {
  .scrolling-text {
    font-size: 24px;
  }
  .CompanyMainImg-text-1 {
    font-size: 22px;
  }
  .CompanyMainImg-text-2 {
    font-size: 14px;
  }
  .aboutus-landing {
    padding: 20px 10px;
  }
  .bottomlanding-2 img {
    width: 20px;
  }

  .CompanyMainImg-container {
    
    width: 100%;
  }
  
  .CompanyMainImg-container img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  

}








