.ALLtrip-list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.ALLtrip-list .trip-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.ALLtrip-list .trip-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  width: 300px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.ALLtrip-list .trip-card h3 {
  margin: 0 0 10px;
}

.ALLtrip-list .trip-card p {
  margin: 5px 0;
}

.ALLtrip-list .trip-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
}