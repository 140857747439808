* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}


.signup {
    /* height: 100vh; */
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
}


.signup .signup-form {

    width: 400px;
    padding: 2em;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.signup .signup-form h2 {
    text-align: center;
    margin-bottom: 1em;
}

.signup .form-group {
    margin-bottom: 1em;
}

.signup .form-group label {
    display: block;
    margin-bottom: 0.5em;
    text-align: left;

}

.signup .form-group input {
    width: 100%;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 28px;
}

.signup .required_symbol {
    color: red;
}

.signup .error {
    color: #ff0000;
    background-color: #ffe6e6;
    border: 1px solid #ff0000;
    padding: 0.5em;

    /* padding: 8px; */
    margin-top: 10px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
}

.signup .success {
    color: #008000;
    background-color: #e6ffe6;
    border: 1px solid #008000;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
}

/* password validation */
.signup .password-criteria {
    border: 1px solid #ccc;
    padding: 0.5em;
    background-color: #f9f9f9;
    border-radius: 5px;
    width: 100%;
    margin-top: 10px;
    font-family: Arial, sans-serif;
}

.signup .password-criteria label {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

.signup .password-criteria div {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.signup .password-criteria input[type="checkbox"] {
    margin-right: 10px;
}
/* ------------------------- */

.signup .login-link {
    color: blue;
    cursor: pointer;
}

/* Media Queries */
@media (min-width: 1300px) and (max-width: 1350px) {
    .signup {
        padding: 6rem 0;
        /* Adjust padding for screens between 900px and 999px */
    }
}

@media (min-width: 1300px) {
    .signup {
        padding: 4rem 0;
        /* Adjust padding for screens 1000px and above */
    }
}