.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.button_01 {
  padding: 9px 15px 9px 15px;
  min-width: 100px;
  width: auto;
  border: none;
  background-color: #037afa;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  margin: 10px;
}

.button_01:hover {
  background-color: #0056b3;
}

.button_02 {
  padding: 9px 15px 9px 15px;
  min-width: 100px;
  width: auto;
  border: none;
  background-color: #277c3a;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  margin: 10px;
}

.button_02:hover {
  background-color: #09d658;
}

.button_03 {
  padding: 9px 15px 9px 15px;
  min-width: 100px;
  width: auto;
  border: none;
  background-color: #e71313;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  margin: 10px;
}

.button_03:hover {
  background-color: #6b0606;
}

.button_04 {
  padding: 5px;
  min-width: 60px;
  width: auto;
  border: none;
  background-color: #0c0c0c;
  color: white;
  border-radius: 8px;
  /* cursor: pointer; */
  /* margin: 10px; */
}

