/* Notifications Container */
.notifications-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Title */
  .notifications-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  /* Notifications List */
  .notifications-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Notification Item */
  .notification-item {
    background: #fff;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .notification-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Notification Title */
  .notification-title {
    font-size: 18px;
    font-weight: bold;
    color: #007BFF;
    margin-bottom: 5px;
  }
  
  /* Notification Message */
  .notification-message {
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
  }
  
  /* Notification Date */
  .notification-date {
    font-size: 12px;
    color: #999;
    display: block;
    text-align: right;
  }
  
  /* No Notifications Message */
  .no-notifications {
    text-align: center;
    font-size: 16px;
    color: #666;
  }
  
  /* Error Message */
  .error {
    text-align: center;
    color: red;
    font-size: 16px;
    margin-top: 20px;
  }
  