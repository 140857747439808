* {
    box-sizing: border-box;
}

@media screen and (max-width: 600px) {

    .col-25,
    .col-75,
    input[type='submit'] {
        width: 100%;
        margin-top: 0;
    }
}

@media screen and (max-width: 900px) {
    .addjob .container {
        display: flex;
        flex-direction: column;
    }

    .addjob .rightSide-container-2 {
        padding: 10px !important;
    }
}

.Updatepassword {
    margin-bottom: 20px;
    padding-bottom: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

}

.Updatepassword .container {
    display: flex;
    border-radius: 9px;
    border: 1px solid black;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;
}

.Updatepassword .leftSide-container {
    padding: 20px;
    width: 100%;
}

.Updatepassword label {
    width: 100%;
    padding: 10px 10px 10px 0;
    display: inline-block;
    text-align: left;
}

.Updatepassword .col-25 {
    float: left;
    width: 40%;
    margin-top: 6px;
    padding-left: 100px;
}

.Updatepassword .col-75 {
    float: left;
    width: 60%;
    margin-top: 6px;
}


.Updatepassword input[type='password'],
.Updatepassword input[type='text'] {
    width: 70%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #f2f2f2;
}

/* Clear floats after the columns */

.Updatepassword .row:after {
    content: '';
    display: table;
    clear: both;
}

.Updatepassword .mandatory-indicator {
    color: red;
    margin-left: 5px;
}

.Updatepassword .error {
    color: #ff0000;
    background-color: #ffe6e6;
    border: 1px solid #ff0000;
    padding: 0.5em;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
}

.Updatepassword .success {
    color: #008000;
    background-color: #e6ffe6;
    border: 1px solid #008000;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
}

/* password validation */
.Updatepassword .password-criteria {
    border: 1px solid #ccc;
    padding: 0.5em;
    background-color: #f9f9f9;
    border-radius: 5px;
    width: 40%;
    margin-top: 10px;
    margin-left: 50%;
    font-family: Arial, sans-serif;
}

.Updatepassword .password-criteria label {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

.Updatepassword .password-criteria div {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.Updatepassword .password-criteria input[type="checkbox"] {
    margin-right: 10px;
}


/* password show and hide */
.Updatepassword .col-75 {
    position: relative;
}

.Updatepassword .pwd-img {
    position: absolute;
    top: 50%;
    right: 20%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 24px;
}


/* ------------------------- */