.company-logo {
  max-height: 70px;
}

.call-icon {
  width: 20px;
}

@media (max-width: 992px) {
  .navbar-nav {
    flex-direction: column;
    align-items: center;
  }

  .navbar-collapse {
    text-align: center;
  }

  .dropdown-menu {
    text-align: center;
  }

  .company-logo {
    height: 40px !important;
  }

  .leftside {
    display: flex !important;
    flex-direction: column;
  }

  .navbar > .container {
    justify-content: flex-start !important;  /* or center, depending on your layout */
    width: 100%;
  }
  .social-media {
    display: flex;
    justify-content: space-around;
    padding-top: 5px;
  }



}