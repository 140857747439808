

* {
    box-sizing: border-box;
}
 
@media screen and (max-width: 600px) {
    .col-25,
    .col-75,
    input[type='submit'] {
        width: 100%;
        margin-top: 0;
    }
}

@media screen and (max-width: 900px) {
    .addjob .container {
        display: flex;
        flex-direction: column;
    }

    .addjob .rightSide-container-2 {
        padding: 10px !important;
    }
}

.Trip {
    margin-bottom: 20px;
    padding-bottom: 5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border-bottom: 2px solid black;
    background-color: white;

}

.container {
    display: flex;
    /* margin-bottom: 20px; */
    border-radius: 9px;
    border: 1px solid black;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #ffffff;
}

.Tripcontainer {
    padding: 20px;
    width: 100%;
}



.Trip label {
    width: 100%;
    padding: 10px 10px 10px 0;
    display: inline-block;
    text-align: left;
}

.Trip .col-25 {
    float: left;
    width: 40%;
    margin-top: 6px;
    padding-left: 100px;
}

.Trip .col-75 {
    float: left;
    width: 60%;
    margin-top: 6px;
}

.Trip input[type='text'],
.Trip input[type='number'],
.Trip input[type='date'],
.Trip input[type='email'],
.Trip input[type='password'],
.Trip select,
.Trip textarea {
    width: 70%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #f2f2f2;
}

/* Clear floats after the columns */
.Trip .row:after {
    content: '';
    display: table;
    clear: both;
}

.Trip .buttonHolder {
    display: flex;
    justify-content: space-around;
    background-color: #ffffff;
    margin: 10px 0 10px 0 ;
}

.Trip .mandatory-indicator {
    color: red;
    margin-left: 5px;
}

.Trip .error {
    color: #ff0000;
    background-color: #ffe6e6;
    border: 1px solid #ff0000;
    padding: 0.5em;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
}

.Trip .success {
    color: #008000;
    background-color: #e6ffe6;
    border: 1px solid #008000;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
}
