

/* Trip Details  */

.trips-details {
  margin: 20px;
    padding: 20px;
    background-color: transparent;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .trips-details h5 {
    text-align: center;
    color: gold;
    margin: 10px 0 10px 0;
  }
  
  .trip-details p {
    font-size: 1rem;
    color: whitesmoke;
    text-align: center;
  }


  .trip-card {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 8px;
    overflow: hidden;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    transition: transform 0.3s;
    border: 1px solid whitesmoke;
    margin-top: 5px;
    width: 95% !important;
    min-height: 650px;
    background-color: #333 ;
  }
  
  .trip-card:hover {
    transform: translateY(-5px);
  }
  
  .trip-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    padding: 10px;
    border-radius: 20px;
  }
  
  .Trip-data {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 15px;
  }
  
  .trip-flight,
  .trip-Hotels,
  .trip-activities {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .Trip-data img {
    width: 20px;
  }
  
  .trip-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    width: 100%;
  }
  
  .trip-price-normal,
  .trip-price-discount {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 1rem;
    color: #333;
    margin-bottom: 0px;
  }
  
  .price-row {
    display: flex;
    align-items: center;
  }
  
  .price-row img {
    width: 20px;
  }
  
  .per-person {
    font-size: 0.8rem;
    color: #999;
  }
  
  .trip-price-normal p {
    font-size: 1.1rem;
    color: #999;
    text-decoration: line-through;
    text-decoration-color: red;
    margin-bottom: 0px;
  }
  
  .trip-price-discount p {
    font-size: 1.5rem;
    font-weight: bold;
    color: green;
    margin-bottom: 0px;
  }
  
  

  .slick-dots li button:before {
    color: whitesmoke !important;
    
  }
  
  
  .carousal-inquiry {
    border: 1px solid white;
    border-radius: 8px;
    display: flex;
    height: 550px;
    padding: 25px;
    margin: 10px;
  }
  
  
  
  .trip-img,
  .inquiry-bookTrip {
    flex: 1;
  
  }
  
  .trip-img {
    padding-right: 10px;
  }
  
  .inquiry-bookTrip {
    padding: 0px;
    background-color: #e9ecef;
    border-radius: 8px;
  }
  
  .carousel-item {
    align-items: center;
    justify-content: center;
  }
  
  .carousel-item img {
    height: 500px;
    border-radius: 9px;
  }
  



  