


/* General Container */
.trip-details-container {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
}

/* Header */
.trip-details-container .trip-header {
  text-align: center;
  margin-bottom: 20px;
}

.trip-details-container .trip-title {
  font-size: 28px;
  font-weight: bold;
  color: #ff6600;
}

.trip-details-container .trip-location {
  font-size: 18px;
  color: gray;
}

/* Image & Overview Section */
.trip-details-container .trip-overview {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}

.trip-details-container .trip-details-image {
  flex: 1;
  max-width: 400px;
}

.trip-details-container .trip-details-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

.trip-details-container .trip-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  text-align: justify;
}

/* Cards Section */
.trip-details-container .trip-columns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.trip-details-container .trip-card {
  flex: 1;
  min-width: 250px;
  max-width: 350px;
  /* background: white; */
  padding: 15px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  color: antiquewhite;
  background-color: #333 ;
  text-align: justify;
}

.trip-card:hover {
  transform: translateY(-5px);
}

.trip-card h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #ff6600;
}

.trip-card ul {
  list-style: none;
  padding: 0;
}

.trip-card li {
  padding: 5px 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Footer */
.trip-footer {
  text-align: center;
  margin-top: 20px;
}

.button_04 {
  width: 100%;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #058660;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.button_04:hover {
  background-color: #0c6146;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .trip-overview {
    flex-direction: column;
    text-align: center;
  }

  .trip-details-image {
    max-width: 100%;
  }

  .trip-info {
    text-align: left;
  }

  .trip-columns {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .trip-title {
    font-size: 24px;
  }

  .trip-location {
    font-size: 16px;
  }

  .trip-card {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .trip-title {
    font-size: 20px;
  }

  .trip-location {
    font-size: 14px;
  }

  .trip-card {
    padding: 10px;
  }

  .button_02 {
    font-size: 16px;
    padding: 8px 15px;
  }
}
