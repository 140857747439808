

.navbarHome {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
    padding: 10px 20px;
    background-color: #d8e6ec;
}
.nav-leftside {
    display: flex;
    align-items: center;
}


.nav-leftside img {
    cursor: pointer;
    width: 10%;
    border-radius: 8px;
}

.dropdown-trip {
    margin-left: 10px;
}


.nav-rightside {
    display: flex;
    align-items: center;
}

.dropdown-menu {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 250px;
}


.dropdown-item span:first-child {
    font-weight: bold;
    margin-bottom: 5px;
    color: #077fcf;
}

.dropdown-item span:last-child {
    font-size: 0.9em;
    color: #7f8c8d;
}

.dropdown-item:hover {
    background-color: #d0eaf5;
}

.nav-rightside .btn {
    position: relative;
}

.notification-badge {
    position: absolute;
    top: -5px;
    right: -1px;
    background-color: #ee220b;
    color: white;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 50%;
}

.loading-spinner {
    text-align: center;
    padding: 10px;
}




.notification-unopened {
    background-color: #f0f8ff;
    font-weight: bold;
}

.notification-opened {
    background-color: #e9ecef;
    color: #6c757d;
    font-weight: normal;
}

.notification-badge {
    background-color: red;
    color: white;
    font-size: 12px;
    border-radius: 50%;
    padding: 2px 6px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.notification-title {
    font-weight: bold;
    display: block;
}

.notification-message {
    display: block;
    margin-top: 5px;
}

.notification-date {
    font-size: 12px;
    color: #6c757d;
    margin-top: 5px;
    display: block;
}


.notification-badge {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: red;
    color: white;
    font-size: 12px;
    border-radius: 50%;
    padding: 4px 6px;
}
