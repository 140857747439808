
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

h3 {
  text-align: center;
  margin-bottom: 20px;
}

.mandatory-indicator {
  color: red;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/* Carousel and Form Container */
.booking-inquiry {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch; /* Ensures equal height columns */
  padding: 20px;
}

.trip-img,
.inquiry-bookTrip {
  flex: 1;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

/* Carousel Styles */
.carousel-inner img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.carousel-caption {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;
}

.carousel-caption h5 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.carousel-caption p {
  font-size: 1rem;
  font-weight: 400;
}

.inquiry-bookTrip {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

form {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: auto;
}

.form-label {
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.form-control:focus {
  border-color: #80bdff;
  outline: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .booking-inquiry {
    flex-direction: column !important;
    padding: 10px;
  }

  .trip-img,
  .inquiry-bookTrip {
    margin: 5px 0;
  }

  .carousel-caption h5 {
    font-size: 1.2rem;
  }

  .carousel-caption p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .carousel-caption h5 {
    font-size: 1rem;
  }

  .carousel-caption p {
    font-size: 0.8rem;
  }
}
