

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.login {
  height: 100vh;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.login .login-form {
  width: 400px;
  padding: 2em;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.login .login-form h2 {
  text-align: center;
  margin-bottom: 1em;
}

.login img {
  width: 150px;
  height: auto;
  padding-bottom: 20px;
  display: block;
  margin: 0 auto;
}

.login .form-group {
  margin-bottom: 1em;
}

.login .form-group label {
  display: block;
  margin-bottom: 0.5em;
  text-align: left;
}

.login .form-group input {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 28px;
}

.login .error-message {
  color: #ff0000;
  background-color: #ffe6e6;
  border: 1px solid #ff0000;
  padding: 8px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
}

.login .success {
  color: #008000;
  background-color: #e6ffe6;
  border: 1px solid #008000;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
}

.login .password-container {
  position: relative;
}

.login .pwd-img {
  position: absolute;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 24px; 
}


.login .signup-link {
  text-align: center;
  margin-top: 1em;
}
